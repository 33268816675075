import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
import moment from "moment";


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: "List of all new employees",
            moreParams: {},
            base_url: Vue.BASE_URL,
            typedocument: "",
            fields: [
                // {
                //     name: VuetableFieldCheckbox,
                //     titleClass: 'text-center',
                //     dataClass: 'text-center'
                // },
                {
                    name: 'job_id',
                    title: 'Job ID',
                    sortField: 'job_id',
                    dataClass: 'text-center',
                    width:'60px'
                },
                {
                    name: 'Ambatovy_ID',
                    title: 'Ambatovy_ID',
                    dataClass: 'text-center',
                    sortField: 'Ambatovy_ID',
                    width:'100px'
                },
                {
                    name: 'emp_ename',
                    title: 'Name',
                    sortField: 'emp_ename'
                },
                {
                    name: 'last_ename',
                    title: 'Last Name',
                    sortField: 'last_ename'
                },
                {
                    name: 'Position',
                    title: 'Position',
                    sortField: 'Position'
                },
                {
                    name: 'Remarks',
                    title: 'Remarks',
                    sortField: 'Remarks'
                },
                {
                    name: 'Department',
                    title: 'Department',
                    sortField: 'Department'
                },
                {
                    name: 'emp_address',
                    title: 'Address',
                    sortField: 'emp_address'
                },
                {
                    name: 'actions',
                    width: "50px",
                    dataClass: "text-center"
                }

            ],
            sortOrder: [
                { field: 'job_id', direction: 'asc' }
            ],
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hovered',
                    loadingClass: 'loading',
                    ascendingIcon: 'glyphicon glyphicon-chevron-up',
                    descendingIcon: 'glyphicon glyphicon-chevron-down',
                    handleIcon: 'glyphicon glyphicon-menu-hamburger',
                },
                pagination: {
                    infoClass: 'pull-left ',
                    wrapperClass: 'vuetable-pagination text-center',
                    activeClass: 'btn-secondary',
                    disabledClass: 'disabled',
                    pageClass: 'btn btn-border',
                    linkClass: 'btn btn-border',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                }
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'Job ID': 'job_id',
                'Ambatovy ID': 'Ambatovy_ID',
                'Name': 'emp_ename',
                'Last Name': 'last_ename',
                'Position': 'Position',
                'Remarks': 'Remarks',
                'Department': 'Department',
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],
            datedocument: "",
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            preview_link: "",
            logo: null,
            show_progress: false,
            file_name: "",
            datemodaltitle: "Document date",
            newworkermodaltitle: "Import new employee",
            url_print: "",
            progressValue: 0,
            url_fichier:"",
            nom_fichier:""

        }
    },
    methods: {
        highlight(row) {
            if (row.data) return this.highlightedRow === row.data.vueTableComponentInternalRowId ? 'highlight' : '';
            return '';
        },
        rowClicked(row) {
            console.log(row);
            this.expand = false;
            this.log = eval('`' + this.runs.results[row.data.id].logFile + '`');
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {
            alert("You clicked edit on" + JSON.stringify(rowData))
        },
        deleteRow(rowData) {
            alert("You clicked delete on" + JSON.stringify(rowData))
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.tabledata = this.$refs.vuetable.$data.tableData;
            this.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        onRowClass(dataItem) {
            let selected = this.$refs.vuetable.selectedTo;
            let result = "c-contacts-index__row";
        
            if (selected.includes(dataItem.id)) {
              result += " c-contacts-index__row--active";
            }

            // console.log(result);
        
            return result;
        },
        closeFiltreModal(){
            this.$bvModal.hide("datemodal");
        },
        openModal(){
            this.$bvModal.show("datemodal");
        },
        printAll() {
            if(this.typedocument != "") { 
                this.url_print = this.base_url+"/employeenew/"+this.typedocument;
                if(this.typedocument == "noticederenseignement") {
                    window.open(this.base_url+"/employeenew/"+this.typedocument);
                }
                else {
                    this.openModal();
                }
            } else {
                Vue.$toast.open({
                    message: "Please select the document type!",
                    type: 'error',
                    position: 'top-right',
                    duration: 3000,
                    // all of other options may go here
                });
            }
        },
        printDocument() {
            window.open(this.url_print+'?date='+this.datedocument);
            this.closeFiltreModal();
        },
        closeImportModal(){
            this.$bvModal.hide("newworkermodal");
        },
        openImportModal(){
            this.$bvModal.show("newworkermodal");
        },
        uploadImage(event) {
            this.show_progress = true;
            const URL = this.BASE_URL + '/employeenew/uploadaction';

            let data = new FormData();
            data.append('name', 'file-input');
            data.append('file-input', event.target.files[0]);
            console.log(event.target.files[0]);
            var files = event.target.files || event.dataTransfer.files;
            this.createImage(files[0]);

            let config = {
                header: {
                    // 'Content-Type': 'image/*',
                    // 'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    this.progressValue = percentCompleted;
                    if(Number(percentCompleted) >= 100) {
                        
                        setTimeout(function(){ 
                            this.show_progress = false;
                            this.progressValue = 0;
                        }, 2000);
                    }
                    console.log(percentCompleted)
                }
            }
            var that = this;
            axios.post(
                URL,
                data,
                config
            ).then(
                response => {
                    console.log('image upload response > ', response);
                    that.show_progress = false;
                    that.file_name = response.data.upload_data.file_name;
                    that.progressValue = 0;

                    that.url_fichier = response.data.upload_data.full_path;
                    that.nom_fichier = response.data.upload_data.file_name;

                }
            ).catch(error => {
                console.log(error);
            })
        },
        createImage(file) {
            var reader = new FileReader();
            var that = this;

            reader.onload = (e) => {
                that.preview_link = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        initLogo: function() {
            this.preview_link = "";
            this.file_name = "";
            this.logo = null;
        },
        onSubmit() {
            var that =  this;
            this.$bvModal.msgBoxConfirm('Are you sure you want to continue importing?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continue',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);
                if(value == true) {
                    axios.post(that.base_url+"/employeenew/importdatanew", $("#formulairenewworkermodal").serialize()).then(function(response) {
                        console.log(response);
                        //this.message_reponse = response.data.msg;
                        if (response.data.error === "") {
                            // console.log(response.data.message);
                            that.closeImportModal();
                            that.resetFilter();
                            Vue.$toast.open({
                                message: response.data.message,
                                type: 'success',
                                position: 'top-right',
                                duration: 3000
                                // all of other options may go here
                            });
                        } else {
                            console.log("Erreur");
                            Vue.$toast.open({
                                message: response.data.error,
                                type: 'error',
                                position: 'top-right',
                                duration: 0
                                // all of other options may go here
                            });
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                // An error occurred
            })
            

            
        },
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        
        this.motCle = "";
        this.criteriacol = "";
        
        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.datedocument = currentDateWithFormat;
    },
    mounted: function() {
        // var that = this;

        /*axios.get("https://vuetable.ratiw.net/api/users").then(response => {
            this.fields = response.data.data;
        });*/

        // On change zone de texte
        /*that.moreParams.filter = 'Mot a rechercher';
            Vue.nextTick(() => that.$refs.vuetable.refresh());*/
    }
}